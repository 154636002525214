import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Layout, Portfolio } from "../components"
import Seo from "../components/seo"
import SearchButtons from "../components/SearchButtons"
import InnerHero from "../components/InnerHero"

const query = graphql`
  {
    portfolioLists: allPrismicPortfolio {
      nodes {
        id
        href
        uid
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
            url
          }
          info {
            text
          }
          link {
            target
            url
          }
          slug {
            text
          }
          title {
            text
          }
          type {
            text
          }
          technologies {
            technology {
              text
            }
          }
        }
      }
    }
  }
`

const PortfolioPage = ({ page }) => {
  const {
    portfolioLists: { nodes: portfolioLists },
  } = useStaticQuery(query)
  // projectsAll -> array objects 4 items

  const [portfolios, setPortfolios] = useState(portfolioLists)
  // Set back to default value
  const setBackToAll = () => {
    setPortfolios(portfolioLists)
  }

  return (
    <Layout>
      <Seo
        title="Web design & web development near me - Vermont Web Development"
        description="Portfolio of Law Firms, Contractors, Restaurants & Bars, and Retail Web Design and Website Development with Jamstack and Headless CMS, The Vermont web development company builds high-performing, safe, SEO friendly, and easy to maintain, scalable, and responsive web development for your business."
      />
      <main>
        <section id="portfolio">
          <div className="section u-padding-bottom">
            {!page && (
              <>
                <InnerHero>
                  <h1 className="heading__main-title heading__main-title--black">
                    Portfolio Highlights
                  </h1>
                  <h2 className="heading__main-title--sub u-no-effect">
                    I've helped businesses boost their sales. Here are some of
                    those projects.
                  </h2>
                </InnerHero>
              </>
            )}
          </div>

          <section className="portfolio__center section u-padding">
            <div className="u-column-wrap u-left-text">
              <div className="row">
                <div className="col-1-of-1">
                  <SearchButtons
                    portfolios={portfolioLists}
                    setPortfolios={setPortfolios}
                    setBackToAll={setBackToAll}
                  />
                </div>
              </div>
            </div>

            <Portfolio portfolios={portfolios} page />
          </section>
        </section>
      </main>
    </Layout>
  )
}

export default PortfolioPage

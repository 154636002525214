import React, { useState } from "react"

const SearchButtons = ({ portfolios, setPortfolios, setBackToAll }) => {
  const [index, setIndex] = useState(0)

  // Get only unique value ["all", "bathroom", "kitchen", "bedroom"]
  const types = [
    "all",
    ...new Set(
      portfolios.map(portfolio => {
        return portfolio.data.type.text
      })
    ),
  ]

  const showPortfolio = (type, typeIndex) => {
    setIndex(typeIndex)
    // Set back to default value
    if (type === "all") {
      setBackToAll()
      // Set value that match type
    } else {
      const tempPortfolios = portfolios.filter(
        portfolio => portfolio.data.type.text === type
      )
      setPortfolios(tempPortfolios)
    }
  }

  return (
    <section id="search-buttons" className="u-margin-bottom-medium">
      {types.map((type, typeIndex) => {
        return (
          <button
            key={typeIndex}
            className={index === typeIndex ? "active" : undefined}
            onClick={() => showPortfolio(type, typeIndex)}
          >
            {type}
          </button>
        )
      })}
    </section>
  )
}

export default SearchButtons
